import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet molt petit en forma de copa allargada, de 9 a 15 mm d’altura i de 7 a 13 mm de diàmetre. La base és estreta i la boca ample. La superfície externa és tomentosa i de color gris marró i la superfície interna és llisa, brillant i de color entre gris pàl·lid a obscur. Dins la �copa� trobem una espècie d’ous petits de 2 a 3 mm, en forma de llentilla de color gris, brillants i estan amarrats a la paret per un filament i que contenen les espores. Aquestes són el·lipsoidals de 9-12 x 7-9 micres i quan plou, les gotes fan que aquests �ous� projectin les espores cap a fora.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      